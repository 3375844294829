.react-tagsinput {
  border-radius: 0.75rem;
  background: rgb(245 245 245 / 1) !important;
  border-color: transparent !important;

  .react-tagsinput-tag {
    background: rgb(135 198 232 / 0.2);
    border: none;
    border-radius: 0.75rem;
    color: rgb(135 198 232 / 1);
  }

  &.react-tagsinput--focused {
    border-color: transparent !important;
  }

  input {
    outline: none !important;
    border-color: transparent !important;
    &:focus {
      border: none !important;
    }

    &:active {
      border: none !important;
    }
  }
}

.multiInput {
  .fieldLabel {
    margin-bottom: 8px;
  }
}
