.primaryBtn {
  @apply bg-primary shadow-primaryBtn p-3 rounded-xl text-white text-sm leading-sm font-semibold hover:opacity-90 flex items-center justify-center disabled:opacity-25;
}

.secondaryBtn {
  @apply bg-danger py-3 px-5 rounded-xl text-white text-sm font-medium hover:opacity-90 flex items-center justify-center gap-2 disabled:opacity-25;

  svg {
    @apply w-5 h-5 text-white;
  }
}

.iconBtn {
  @apply p-3 rounded-md shadow-card;
  svg {
    @apply w-5 h-5 text-tertiary;
  }

  &.danger {
    svg {
      @apply text-danger;
    }
  }
}

.outlinedBtn {
  @apply text-sm font-medium text-danger border border-danger py-2 px-3 rounded-lg hover:border-white hover:bg-danger hover:text-white;
}

.outlinedPrimaryBtn {
  @apply flex items-center justify-center gap-2 text-sm font-medium text-light-200 border border-primary py-3 px-5 rounded-lg hover:border-white hover:bg-primary hover:text-white;

  svg {
    @apply w-4 h-4 transition-none;
  }
}
