/* width */
.custom-scrollbar {
  &::-webkit-scrollbar {
    height: 0px;
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
    background: black;
  }
  &::-webkit-scrollbar-track {
    background: transparent; 
  }
  &::-webkit-scrollbar-thumb {
    background: #CCC;
    &:hover {
      background: #888; 
    }
  }
}
